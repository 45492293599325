import { GTMEventShopContext } from '@x/common/tracking/providers/google-tag-manager/events/gtm-event-shop-context';
import { IShopOrderItem, IShopProductItem, IShopProductVariant } from '@x/ecommerce/shop-client';
import { IGtmEvent, IGtmProduct } from '../models';

export class GtmEvent {
  public event: IGtmEvent;
  private _genericData: Record<string, any>;

  get shopContext() {
    return GTMEventShopContext.getInstance().getContext();
  }

  constructor(eventType: IGtmEvent) {
    this.event = eventType;
  }

  public shopItemToProduct(product: IShopProductItem, variant?: IShopProductVariant): IGtmProduct {
    const price = variant?.price.amount ?? product.variants[0].price.amount;
    const variantId = variant?.id ?? product.variants[0].id;

    return {
      id: product.id,
      name: product.name,
      brand: this.shopContext.brand,
      category: product.mainTaxon.slug.split('/').slice(1).join('/'),
      item_list_id: product.mainTaxonId,
      variant: variant?.name ?? product.name,
      price: price / 100,
      pixel_id: `${this.shopContext.flavor.toUpperCase()}-P${product.id}-V${variantId}`,
    } as IGtmProduct;
  }

  public orderItemToProduct(product: IShopOrderItem): IGtmProduct {
    const variantId = product.variantId;
    return {
      id: product.productId,
      name: product.productName,
      brand: this.shopContext.brand,
      variant: product.variantName ?? product.productName,
      price: product.unitPrice / 100,
      quantity: product.quantity,
      pixel_id: `${this.shopContext.flavor.toUpperCase()}-P${product.productId}-V${variantId}`,
      ...(product.context?.associationId && { associationId: product.context?.associationId }),
      ...(product.context?.origin && { origin: product.context.origin }),
      ...(product.context?.shopAdId && { shopAdId: product.context.shopAdId }),
      ...(product.context?.taxonId && { taxonId: product.context.taxonId }),
      ...(product.context?.shopAdPlacement && { shopAdPlacement: product.context.shopAdPlacement }),
    };
  }

  public convertToProduct(
    product: IShopProductItem | IShopOrderItem,
    variant?: IShopProductVariant,
  ): IGtmProduct {
    let productData: IGtmProduct;

    switch (product.__typename) {
      case 'ShopOrderItemObject':
        productData = this.orderItemToProduct(product);
        break;
      default:
        productData = this.shopItemToProduct(product, variant);
    }

    return productData;
  }

  public genericEvent(data: Record<string, any>): void {
    this._genericData = data;
  }

  public toEvent(): Record<string, any> {
    return { event: this.event, ...this._genericData };
  }
}
