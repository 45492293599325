import { TrackingEvent } from '@x/common/tracking/event-models/event';
import { TrackingEventName, TShopRouteOrigin } from '@x/common/tracking/tracking.types';
import { IShopAddress, IShopCheckoutOrderShipment, IShopOrderItem } from '@x/ecommerce/shop-client';

export type TCartEventFlow = 'add' | 'remove';

type TCartData = {
  items: IShopOrderItem[];
  flow?: TCartEventFlow;
  currency?: string | null;
};

type TCartEventArgs<Data> = {
  routeOrigin: TShopRouteOrigin;
  data: Data;
};
abstract class CartEvent<Data> extends TrackingEvent<TCartEventArgs<Data>> {
  [TrackingEventName] = 'cart';

  constructor(routeOrigin: TShopRouteOrigin, data: Data) {
    super({ routeOrigin, data });
  }

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('cart', obj);
  }
}

export class TECartUpdateItem extends CartEvent<TCartData> {
  [TrackingEventName] = 'cart:update';

  constructor(origin: TShopRouteOrigin, data: TCartData) {
    super(origin, data);
  }

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('cart:update', obj);
  }
}

export class TECartRemoveItem extends CartEvent<TCartData> {
  [TrackingEventName] = 'cart:remove';

  constructor(origin: TShopRouteOrigin, data: TCartData) {
    super(origin, data);
  }

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('cart:remove', obj);
  }
}

interface TECartShippingAddressData {
  address: IShopAddress;
}
export class TECartShippingAddress extends CartEvent<TECartShippingAddressData> {
  [TrackingEventName] = 'cart:shipping-address';

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('cart:shipping-address', obj);
  }
}

interface TECartCollectionData {
  shipment: IShopCheckoutOrderShipment;
}
export class TECartCollection extends CartEvent<TECartCollectionData> {
  [TrackingEventName] = 'cart:collection';

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('cart:collection', obj);
  }
}

interface TECartDeliverySlotData {
  requestedSlotId: string;
}
export class TECartDeliverySlot extends CartEvent<TECartDeliverySlotData> {
  [TrackingEventName] = 'cart:delivery-slot';

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('cart:delivery-slot', obj);
  }
}
