import {
  IShopCancelCartPaymentInput,
  IShopCheckoutOrder,
  IShopCheckoutOrderDeleteItemMutation,
  IShopCheckoutOrderRequestShipmentSlotMutation,
  IShopCheckoutOrderUpdateAddressMutation,
  IShopCheckoutOrderUpdateCollectionPointMutation,
  IShopCheckoutOrderUpdateCouponMutation,
  IShopCheckoutOrderUpdateGiftOptionsMutation,
  IShopCheckoutOrderUpdateInstructionsMutation,
  IShopCheckoutOrderUpdateItemMutation,
  IShopCreateNewCartPaymentInput,
  IShopOrderId,
  IShopPayment,
  IShopPaymentMethodInput,
  IShopReconCheckoutPaymentQuery,
  OmitOrderIdFromInput,
  TShopOrderItemContext,
} from '@x/ecommerce/shop-client';
import { IPaymentMethodOption } from './checkout-order.state';

export class CheckoutFetchOrder {
  static readonly type = '[Checkout] Fetch Shop Checkout Order';
  constructor(public id: IShopOrderId) {}
}

export class CheckoutRefreshOrder {
  static readonly type = '[Checkout] Refresh Shop Checkout Order';
}

export class CheckoutUpdateOrderItem {
  static readonly type = '[Checkout] Update Cart Item';
  constructor(
    public args: OmitOrderIdFromInput<IShopCheckoutOrderUpdateItemMutation>,
    public context?: TShopOrderItemContext,
  ) {}
}

export class CheckoutDeleteOrderItems {
  static readonly type = '[Checkout] Delete Cart Items';
  constructor(public variantIds: number[]) {}
}

export class CheckoutDeleteOrderItem {
  static readonly type = '[Checkout] Delete Cart Item';
  constructor(public args: OmitOrderIdFromInput<IShopCheckoutOrderDeleteItemMutation>) {}
}

export class CheckoutUpdateOrderGiftOptions {
  static readonly type = '[Checkout] Update Cart Gift Options';
  constructor(public args: OmitOrderIdFromInput<IShopCheckoutOrderUpdateGiftOptionsMutation>) {}
}

export class CheckoutUpdateOrderInstructions {
  static readonly type = '[Checkout] Update Cart Instructions';
  constructor(public args: OmitOrderIdFromInput<IShopCheckoutOrderUpdateInstructionsMutation>) {}
}

export class CheckoutUpdateOrderCoupon {
  static readonly type = '[Checkout] Update Cart Coupon';
  constructor(public args: OmitOrderIdFromInput<IShopCheckoutOrderUpdateCouponMutation>) {}
}

export class CheckoutUpdateOrderAddress {
  static readonly type = '[Checkout] Update Cart Address';
  constructor(public args: OmitOrderIdFromInput<IShopCheckoutOrderUpdateAddressMutation>) {}
}

export class CheckoutUpdateOrderCollectionPoint {
  static readonly type = '[Checkout] Update Cart Collection Point';
  constructor(public args: OmitOrderIdFromInput<IShopCheckoutOrderUpdateCollectionPointMutation>) {}
}

export class CheckoutSetOrder {
  static readonly type = '[Checkout] Set Shop Checkout Order';
  constructor(public order: IShopCheckoutOrder) {}
}

export class ConfirmExpressCheckoutPayment {
  static readonly type = '[Checkout] Confirm Shop Express Checkout Payment';
  constructor(public args: IShopCreateNewCartPaymentInput) {}
}

export class ReconCheckoutPayment {
  static readonly type = '[Checkout] Fail Shop Express Checkout Payment';
  constructor(public args: IShopReconCheckoutPaymentQuery) {}
}

export class CancelExpressCheckoutPayment {
  static readonly type = '[Checkout] Cancel Shop Express Checkout Payment';
  constructor(public args: IShopCancelCartPaymentInput) {}
}

export class CheckoutCreatePayment {
  static readonly type = '[Checkout] Create Shop Payment';
  constructor(public args: IShopPaymentMethodInput) {}
}

export class CheckoutCancelPayment {
  static readonly type = '[Checkout] Cancel Shop Payment';
  constructor(public args: number) {}
}

export class CheckoutUpdatePayment {
  static readonly type = '[Checkout] Update Shop Payment';
  constructor(public shopPayment: IShopPayment) {}
}

export class CheckoutSetPaymentMethodType {
  static readonly type = '[Checkout] Set Payment Method Type';
  constructor(public shopPaymentMethodInput: IShopPaymentMethodInput) {}
}

export class CheckoutPaymentOptionType {
  static readonly type = '[Checkout] Set Payment Option Type';
  constructor(public paymentMethodOption: IPaymentMethodOption) {}
}

export class CheckoutSavePaymentMethod {
  static readonly type = '[Checkout] Set Save Payment Method';
  constructor(public savePaymentMethod: boolean) {}
}

export class CheckoutClearPaymentMethodType {
  static readonly type = '[Checkout] Clear Payment Method Type';
}

export class CheckoutClearToken {
  static readonly type = '[Checkout] Clear token';
}

export class CheckoutClearState {
  static readonly type = '[Checkout] Clear state';
  constructor(public redirect: boolean = false) {}
}

export class CheckoutCreateWalletPayment {
  static readonly type = '[Checkout] Create Shop Wallet Payment';
}

export class CheckoutFetchShippingSlots {
  static readonly type = '[Checkout] Fetch Shipping Slots';
}

export class CheckoutRequestShippingSlot {
  static readonly type = '[Checkout] Request Shipping Slot';
  constructor(public args: OmitOrderIdFromInput<IShopCheckoutOrderRequestShipmentSlotMutation>) {}
}

export class CheckoutFetchChannel {
  static readonly type = '[Checkout] Fetch channel';
  constructor(public channelCode: string) {}
}

export class CheckoutEnquireOrder {
  static readonly type = '[Checkout] Enquire order';
}

export class CheckoutOrder {
  static readonly type = '[Checkout] Checkout order';
}
