import { ITrackingShopContext, TrackingProvider } from '@x/common/tracking';
import {
  TEABTestExperimentViewed,
  TEAuthLogin,
  TEAuthLogout,
  TEAuthRegister,
  TECartCollection,
  TECartDeliverySlot,
  TECartRemoveItem,
  TECartShippingAddress,
  TECartUpdateItem,
  TECheckoutAction,
  TECheckoutPayment,
  TECheckoutStepChange,
  TEClickProductItem,
  TEClickShopAd,
  TEConversionMembership,
  TEConversionPurchase,
  TEConversionSubscription,
  TEImpressionProduct,
  TESearchResults,
  TEViewProductDetail,
  TrackingEvent,
} from '@x/common/tracking/event-models';
import {
  TENavigationEnd,
  TENavigationStart,
} from '@x/common/tracking/event-models/navigation.events';
import {
  GTMEventShopContext,
  GtmBasketClickEvent,
  GtmCheckoutActionEvent,
  GtmCheckoutEvent,
  GtmCheckoutPaymentEvent,
  GtmEvent,
  GtmPageLeaveEvent,
  GtmPageViewEvent,
  GtmProductClickEvent,
  GtmProductDetailEvent,
  GtmPurchaseEvent,
} from '@x/common/tracking/providers/google-tag-manager/events';
import { IGtmConfig } from '@x/common/tracking/providers/google-tag-manager/gtm.config';
import { GtmImpressionsService } from '@x/common/tracking/providers/google-tag-manager/services/gtm-impressions.service';
import { GtmService } from '@x/common/tracking/providers/google-tag-manager/services/gtm.service';

export class GtmProvider extends TrackingProvider {
  constructor(
    private config: IGtmConfig,
    private gtmService: GtmService,
    private gtmImpressionsService: GtmImpressionsService,
    private shopContext: ITrackingShopContext,
  ) {
    super();

    GTMEventShopContext.initialize(this.shopContext);
  }

  async init(): Promise<boolean> {
    return await this.gtmService.injectScripts(this.config.containerId);
  }

  sendEvent<T extends object>(event: TrackingEvent<T>): void {
    try {
      if (event instanceof TENavigationEnd) {
        const { channel, locale, utmParams, routeOrigin } = event.context.data;

        this.gtmService.sendEvent(
          new GtmPageViewEvent({
            channel,
            language: locale,
            pageTemplate: routeOrigin,
            utmParams: utmParams,
          }),
        );

        return;
      }

      if (event instanceof TENavigationStart) {
        const { channel, locale, routeOrigin } = event.context.data;

        this.gtmService.sendEvent(
          new GtmPageLeaveEvent({
            channel,
            language: locale,
            pageTemplate: routeOrigin,
          }),
        );

        return;
      }

      if (event instanceof TECheckoutStepChange) {
        const { stepId, data } = event.context;
        const { order, step } = data;

        switch (stepId) {
          case 'shopping-bag': {
            const gtmEvent: GtmCheckoutEvent = new GtmCheckoutEvent('EEViewCheckoutBasket', step);
            gtmEvent.buildEvent(order.items);
            this.gtmService.sendEvent(gtmEvent);

            const gtmEvent2: GtmCheckoutEvent = new GtmCheckoutEvent('EECheckoutUserDetails', step);
            gtmEvent2.buildEvent(order.items);
            this.gtmService.sendEvent(gtmEvent2);
            return;
          }
          case 'delivery': {
            const gtmEvent: GtmCheckoutEvent = new GtmCheckoutEvent('EECheckoutDelivery', step);
            gtmEvent.buildEvent(order.items);
            this.gtmService.sendEvent(gtmEvent);
            return;
          }
          case 'payment': {
            const gtmEvent: GtmCheckoutEvent = new GtmCheckoutEvent('EECheckoutPayment', step);
            gtmEvent.buildEvent(order.items);
            this.gtmService.sendEvent(gtmEvent);
            return;
          }
        }
      }

      if (event instanceof TECheckoutPayment) {
        const { paymentMethod } = event.context.data;

        const gtmEvent: GtmCheckoutPaymentEvent = new GtmCheckoutPaymentEvent();
        gtmEvent.buildEvent(paymentMethod);
        this.gtmService.sendEvent(gtmEvent);
        return;
      }

      if (event instanceof TECheckoutAction) {
        const { action, step } = event.context.data;

        switch (action) {
          case 'Gift': {
            const gtmEvent: GtmCheckoutActionEvent = new GtmCheckoutActionEvent(
              'EECheckoutGift',
              step,
            );

            gtmEvent.buildEvent(action);
            this.gtmService.sendEvent(gtmEvent);
            return;
          }
        }
      }

      if (event instanceof TEImpressionProduct) {
        this.gtmImpressionsService.accumulateEvents(event);
      }

      if (event instanceof TEViewProductDetail) {
        const { product, variant } = event.context.data;

        const gtmEvent: GtmProductDetailEvent = new GtmProductDetailEvent();
        gtmEvent.buildEvent(product, variant);
        this.gtmService.sendEvent(gtmEvent);
        return;
      }

      if (event instanceof TEConversionPurchase) {
        const { order, payment } = event.context.data;

        const gtmEvent: GtmPurchaseEvent = new GtmPurchaseEvent('EEPurchase');
        gtmEvent.fromProduct(payment, order);
        this.gtmService.sendEvent(gtmEvent);
        return;
      }

      if (event instanceof TEConversionSubscription) {
        const { subscriber } = event.context.data;

        const gtmEvent: GtmPurchaseEvent = new GtmPurchaseEvent('EESubscriptionPurchase');
        gtmEvent.fromSubscription(subscriber);
        this.gtmService.sendEvent(gtmEvent);
        return;
      }

      if (event instanceof TEClickProductItem) {
        const {
          routeOrigin,
          data: { productItem },
        } = event.context;

        const gtmEvent: GtmProductClickEvent = new GtmProductClickEvent(routeOrigin);
        gtmEvent.buildEvent(productItem.product);
        this.gtmService.sendEvent(gtmEvent);

        return;
      }

      if (event instanceof TEAuthLogin) {
        const gtmEvent: GtmEvent = new GtmEvent('Login');
        this.gtmService.sendEvent(gtmEvent);
        return;
      }

      if (event instanceof TEAuthLogout) {
        const gtmEvent: GtmEvent = new GtmEvent('Logout');
        this.gtmService.sendEvent(gtmEvent);
        return;
      }

      if (event instanceof TEAuthRegister) {
        const gtmEvent: GtmEvent = new GtmEvent('NewAccountSignUp');
        this.gtmService.sendEvent(gtmEvent);
        return;
      }

      if (event instanceof TECartUpdateItem) {
        const { items, flow } = event.context.data;

        const gtmEvent = new GtmBasketClickEvent(flow ? flow : 'add');
        gtmEvent.buildEvent(items);
        this.gtmService.sendEvent(gtmEvent);
        return;
      }

      if (event instanceof TECartRemoveItem) {
        const { items } = event.context.data;

        const gtmEvent = new GtmBasketClickEvent('remove');
        gtmEvent.buildEvent(items);
        this.gtmService.sendEvent(gtmEvent);

        return;
      }

      if (event instanceof TECartShippingAddress) {
        const gtmEvent = new GtmEvent('EEAssignShippingAddressBasket');
        this.gtmService.sendEvent(gtmEvent);
      }

      if (event instanceof TECartCollection) {
        const gtmEvent = new GtmEvent('EEAssignCollectionPointBasket');
        this.gtmService.sendEvent(gtmEvent);
      }

      if (event instanceof TECartDeliverySlot) {
        const gtmEvent = new GtmEvent('EEAssignDeliverySlotBasket');
        this.gtmService.sendEvent(gtmEvent);
      }

      if (event instanceof TEConversionMembership) {
        const gtmEvent = new GtmEvent('EEMembershipPurchase');
        this.gtmService.sendEvent(gtmEvent);

        return;
      }

      if (event instanceof TEABTestExperimentViewed) {
        const {
          data: { variationId, deviceId, experimentId },
        } = event.context;

        const gtmEvent = new GtmEvent('ExperimentViewed');
        gtmEvent.genericEvent({
          experimentId,
          variationId,
          deviceId,
        });

        this.gtmService.sendEvent(gtmEvent);
        return;
      }

      if (event instanceof TESearchResults) {
        const { result, query } = event.context.data;
        const gtmEvent: GtmEvent = new GtmEvent('SearchResults');
        gtmEvent.genericEvent({ searchQuery: query, searchResults: result.items.length });
        this.gtmService.sendEvent(gtmEvent);
        return;
      }

      if (event instanceof TEClickShopAd) {
        const gtmEvent = new GtmEvent('EEShopAdClick');
        gtmEvent.genericEvent({ ...event.context });
        this.gtmService.sendEvent(gtmEvent);

        return;
      }
    } catch (e) {
      console.log('Error sending event in GTM Provider', e);
    }
  }
}
